@use "Library";

body, html {
  padding: 0;
  margin: 0;
  font-family: "Inter", sans-serif;
}
html {overflow-y: scroll;}
input, textarea {
  font-family: "Inter", sans-serif;
}

#App {
  @include Library.mainPageFormat();
}

#signInPage {
  #introParagraph {
    display: inline;
    text-align: center;
    width: 85%;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
}

#header {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: start;
  align-content: center;
  background-color: black;
  a {
    background-color: black;
    text-decoration: none;
    text-align: center;
    color: white;
    padding: 10px;
    border: 1px solid white;
    width: 45%;
    font-size: 28px;
  }
}

#requestProjects {
  div {
    display: flex;
    align-items: center;
  }
  .haveArrays {
    display: block;
  }
}

.projectDisplay {
  border: 1px solid black;
  border-radius: 5px;
  padding: 0 0 10px 10px;
  margin-bottom: 10px;
  .outerProject {
    cursor: pointer;
  }
  .innerProject {
    .replySection {
      display: flex;
      align-items: center;
      textarea {width: 70%; height: 50px;}
    }
    .admin, .user {
      background-color: rgb(101, 0, 101);
      color: white;
      padding: 2px;
      border-radius: 5px;
      width: 90%;
    }
    .user {background-color: rgb(4, 37, 81);}
  }
}